import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pb-20 md:pb-26 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1 className="heading-two">Thank You!</h1>
              <p>
                We appreciate you taking the time to share your information and
                look forward to speaking with you. In the meantime, feel free to
                get started on your application. It’s super simple and
                ultra-secure. Apply below.
              </p>
              <ButtonSolid
                href="https://markdarling.floify.com/apply-now"
                outboundLink={true}
                text="Start Application"
              />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/19.0 multistep/Thank you page/1.0 hero.jpg"
                loading="eager"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;
export default Page;
